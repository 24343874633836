import React from 'react';
import styles from './PortfolioCard.module.scss';
import { Link } from 'react-router-dom';

interface CardProps {
  imageSrc: string;
  title: string;
  features: string[];
  color: string;
  text: string;
  link: string;
}

const PortfolioCard: React.FC<CardProps> = ({ imageSrc, title, features, color, text, link }) => {
  return (
    <Link className={styles.card} to={link}>
      <img src={imageSrc} alt={`Portfolio item: ${title}`} className={styles.image} />
      <div className={styles.details}>
        <h3>{title}</h3>
        <ul className={styles.features} style={{ backgroundColor: color, color: text }}>
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </div>
    </Link>
  );
};

export default PortfolioCard;
