import React from 'react';
import './Carousel.css'; 

interface CarouselProps {
  children: React.ReactNode;
}

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  return (
    <div className="carousel">
      <div className="carousel-inner">
        {children}
      </div>
    </div>
  );
};

export default Carousel;
