import React from 'react';
import styles from './MainCard.module.scss';

interface CardProps {
  imageSrc: string;
  iconSrc: any[];
  title: string;
  features: string[];
  color: string;
  text: string;
  link: string;
  description: string;
  btnColor: string;
}

const MainCard: React.FC<CardProps> = ({ imageSrc, iconSrc, title, description, btnColor, features, color, text, link }) => {
  return (
    <div className={styles.card} onClick={() => window.location.href = link} style={{ borderBottom: `1px solid ${color}` }}>
      <img src={imageSrc} alt={`Image for ${title}`} className={styles.image} />
      <div className={styles.details}>
        <div className={styles.title__group}>
          <ul className={styles.features} style={{ backgroundColor: color, color: text }}>
            {features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        <div className={styles.card_flex}>
          <div className={styles.icons_card}>
            {iconSrc.map((icon, index) => (
              <img key={index} src={icon} alt={`Icon ${index + 1}`} />
            ))}
          </div>
          <button style={{ backgroundColor: btnColor }} aria-label={`Read case for ${title}`}>
            Read Case
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainCard;
