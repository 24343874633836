import React, { lazy, Suspense, useEffect } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';

import AnimatedCursor from 'react-animated-cursor';

import s from './styles/App.module.scss';
import { Header } from './components/header/header';
import { Home } from './pages/home';
import { Footer } from './components/footer/footer';
import Target from './pages/target';
import Portfolio from './pages/portfolio';

const Dev = lazy(() => import(/* webpackChunkName: "Dev" */ './pages/dev'));
// const Portfolio = lazy(() => import(/* webpackChunkName: "Dev" */ './pages/portfolio'));
const Motion = lazy(() => import(/* webpackChunkName: "Motion" */ './pages/motion'));
const Contacts = lazy(() => import(/* webpackChunkName: "Contacts" */ './pages/contacts'));
const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ './pages/notFound'));
const Design = lazy(() => import(/* webpackChunkName: "Design" */ './pages/design'));
const PixelArt = lazy(() => import(/* webpackChunkName: "PixelArt" */ './pages/pixelArt'));
const Video = lazy(() => import(/* webpackChunkName: "Video" */ './pages/video'));

function App() {
  const { pathname } = useLocation();

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }, [pathname]);

    return null;
  };

  return (
    <>
      {/* {window.innerWidth > 767
        ? ( */}
          <div className={s.adapt__desc}>
          <AnimatedCursor
            color="112, 63, 255"
            innerScale={2}
            innerSize={20}
            outerAlpha={1}
            outerScale={0}
            outerSize={0}
            innerStyle={{
              mixBlendMode: 'difference',
            }}
          />
          </div>
        {/* ) : (
          <> </>
        )} */}
      <div className={s.app}>
        <ScrollToTop />
        <Header />
        <Suspense fallback={(
          <div className={s.loader__wrapper}>
            <div className={s.loader} />
          </div>
        )}
        >
          <Routes>
            <Route element={<Home />} path="/" />
            <Route element={<Portfolio />} path="/portfolio" />
            <Route element={<Dev />} path="/webDev" />
            <Route element={<Design />} path="/design" />
            <Route element={<Motion />} path="/motion" />
            <Route element={<Contacts />} path="/contacts" />
            <Route element={<PixelArt />} path="/pixelart" />
            <Route element={<NotFound />} path="/*" />
            <Route element={<Target />} path="/target" />
            <Route element={<Video />} path="/video_foto" />
          </Routes>
        </Suspense>
        {pathname !== '/contacts' && <Footer />}
      </div>
    </>
  );
}

export default App;
